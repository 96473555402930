@import 'components/global.scss';

.features {

  div {

    padding-right: $scale;
    

  }

  div > svg {

    position: relative;
    float: left;
    margin-right: $scale-2;

  }

  h1 {

    color: $c-blue;
    text-transform: uppercase;

  }
}

.requestExample {
  background: #ECECEC;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  padding-left: 65px;
  margin-bottom: 15px;
  font-size:smaller;
}

.requestExample .httpType {
  position: absolute;
  background: #D8D8D8;
  color: #151515;
  left: 0;
  padding: 20px;
  font-weight: 800;
}

.requestExample .params {
  font-weight: 800;
  color: #6D6D6D;
  padding: 20px;
}

.code {
  max-width: 100%;
  background-color: $c-purple;
  background-image: radial-gradient(closest-corner,  #F4F4F4,  #F4F4F4);
  overflow: auto;
  text-align: left !important;
  font-size:smaller;
  padding:10px;
  height:400px;
  font-family: 'monospace';
        color: '#ffc600';

}
