@import 'components/global.scss';

.nav {

  height: $scale8;
  background-color: $c-purple;
  background-image: radial-gradient(ellipse at top, #172532, #172532);


  > div {

    height: 100%;

  }

  nav {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    a {

      color: white;
      margin-right: $scale;
      text-decoration: none;

      &:last-child {

        margin-right: 0;

      }
    }
  }
}

.logo {

  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%);

}

.transparent {

  background-image: none;
  background-color: transparent;

}
