@import 'components/global.scss';

.hero {

  position: relative;
  color: white;
  text-align: left;
  margin-bottom: $scale2;
  padding-top: $scale;

  h1 {

    display: block;
    font-size: $scale3;
    font-weight: 800;
    margin-bottom: 0;

  }

  h2 {

    display: block;
    color: white;
    font-weight: normal;
    font-size: $scale1;
    margin: $scale-1 0 $scale;

  }

  img {

    margin-bottom: -$scale2;
    box-shadow: 0 $scale $scale1 rgba(0, 0, 0, 0.2);

  }

  &:before {

    content: '';
    position: absolute;
    top: -$scale9;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: $c-purple;
    background-image: radial-gradient(closest-corner, #172532, #172532);

  }

  @media (min-width: 35em){

    text-align: center;
    padding-top: $scale5;

    h1 {

      font-size: $scale4;

    }

    h2 {

      font-size: $scale1;

    }
  }
}

.shad {
  
  margin-bottom: -$scale2;
  box-shadow: 0 $scale $scale1 rgba(0, 0, 0, 0.2);
}
.term {
  max-width: 100%;
  background-color: $c-purple;
  background-image: radial-gradient(closest-corner,  #F4F4F4,  #F4F4F4);
  margin-bottom: -$scale1;
  box-shadow: 0 $scale $scale1 rgba(0, 0, 0, 0.2);
}

.code {
  max-width: 100%;
  background-color: $c-purple;
  background-image: radial-gradient(closest-corner,  #F4F4F4,  #F4F4F4);
  height:500px;
  overflow: auto;
  text-align: left !important;
  font-size:smaller;
  padding:10px;
  font-family: 'monospace';
        color: '#ffc600';
}

.formInline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
/* Add some margins for each label */
.formInline label {
  margin: 5px 10px 5px 0;
}

/* Style the input fields */
.formInline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  width:97%;
}

/* Style the submit button */
.formInline button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
}

.formInline button:hover {
  background-color: royalblue;
}

/* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
@media (max-width: 800px) {
  .formInline input {
    margin: 10px 0;
  }

  .formInline {
    flex-direction: column;
    align-items: stretch;
  }
}

.blurb {

  margin-bottom: $scale3;

  @media (min-width: 35em){

    max-width: $scale16;
    margin-left: auto;
    margin-right: auto;

  }

  @media (min-width: 50em){

    max-width: $scale18;
    margin-bottom: $scale5;

  }
}
