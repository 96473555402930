@import 'components/global.scss';

.form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  > header {

    padding: 0 0 1em 0 !important;

    h2 {

      margin-bottom: $scale-4;

    }

    p {

      color: lighten($c-text, 16%);
      font-size: $scale-1;

    }
  }

  > label {
    margin: 5px 10px 5px 0;
  }

  > input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }

  > button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
  }

  > a {

    display: block;
    margin-bottom: $scale;

  }
}

.loading {

  opacity: 0.5;

}
