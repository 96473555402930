@import 'components/global.scss';

.logo {

  display: block;
  width: $scale13;
  height: $scale7;
  margin: 0 auto $scale6;
  outline: none;

}
