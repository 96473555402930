@import 'components/global.scss';

.header {

  position: relative;
  width: auto;
  z-index: 3;
  background: white;
  padding: $scale2;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;

  @media (max-width: $mobile){

    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale $scale;
    border: none;

  }
}
